.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 102, 204, 0.8);
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.header.fade-in {
  opacity: 1;
}

.first-div {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  transition: transform 0.3s ease, text-decoration 0.3s ease;
}

.first-div:hover {
  transform: translateY(5px);
  text-decoration: underline;
}

.first-div p {
  margin-left: 50px;
  margin-top: 20px;
}

.first-div p a {
  text-decoration: none;
  font-size: 3rem;
  color: inherit;
  opacity: 1;
}

.desktop-nav {
  display: flex;
  justify-content: flex-end;
}

.desktop-nav ul {
  list-style: none;
  display: flex;
  gap: 50px;
  margin-right: 50px;
}

.desktop-nav ul li {
  transition: transform 0.3s ease;
}

.desktop-nav ul a {
  text-decoration: none;
  font-size: 1.5rem;
  color: white;
  opacity: 1;
}

.desktop-nav ul li:hover {
  transform: translateY(5px);
}

.desktop-nav ul a:hover {
  color: darkblue;
}

.hamburger-icon {
  display: none;
  font-size: 2rem;
  margin-right: 30px;
  cursor: pointer;
  color: white;
}

.hamburger-icon:hover {
  color: darkblue;
  transform: translateY(5px);
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }
}

.drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100vh;
  background-color: rgba(0, 102, 204, 0.7);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  transition: right 0.4s ease;
  z-index: 1500;
}

.drawer.open {
  right: 0;
}

.close-drawer {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.close-drawer:hover {
  color: darkblue;
}

.drawer-nav ul {
  list-style: none;
  text-align: center;
  padding: 0;
}

.drawer-nav ul li {
  margin: 20px 0;
}

.drawer-nav ul a {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.drawer-nav ul a:hover {
  color: darkblue;
}

.drawer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
  z-index: 1400;
}

.drawer-backdrop.show-backdrop {
  visibility: visible;
  opacity: 1;
}
