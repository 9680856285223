@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
  margin: 0;
  height: 100%;
  background-color: silver;
}

.App {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: transparent;
}

/* .App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: radial-gradient(
    circle at 50%,
    rgba(0, 0, 0, 0) 60%,
    #dcdcdc 60%
  );
  background-size: 20px 20px;
  opacity: 0.9;
} */

main {
  position: relative;
  z-index: 1;
}

.roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  color: white;
  font-size: 2.5rem;
}

.material-symbols-outlined:hover {
  color: darkblue;
}

