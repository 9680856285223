.about {
  padding: 50px 20px;
  background-color: transparent;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  outline: 5px solid white;
  outline-offset: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 102, 204, 0.8);
}

.about h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 50px;
  color: slategray;
}

.about p {
  font-size: 1.2rem;
  color: white;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.technologies {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.technologies svg {
  font-size: 3rem;
  color: slategray;
  transition: color 0.3s ease, transform 0.3s ease;
}

.technologies svg:hover {
  color: whitesmoke;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }
}
