.contact {
  padding: 50px 20px;
  background-color: transparent;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 50px;
  color: slategray;
}

.contact p {
  color: white;
  margin: 10px 0;
  font-size: 1.5rem;
}

.contact a {
  color: darkblue;
  text-decoration: none;
  margin-left: 15px;
}

.contact p strong {
  margin-left: 15px;
}

.contact a:hover {
  text-decoration: underline;
}

.icon-contact {
  color: white;
  font-size: 2rem;
}

.download-cv-button {
  padding: 10px 20px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 2rem;
}

.download-cv-button:hover {
  background-color: rgba(112, 128, 144, 0.5);
}

.icon-download {
  margin-right: 8px;
}

.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  outline: 5px solid white;
  outline-offset: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 102, 204, 0.8);
}

@media (max-width: 480px) {
  .contact p {
    font-size: 1rem;
  }
}
