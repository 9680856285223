.work-section {
  padding: 50px 20px;
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.work-section h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: slategray;
  text-align: center;
}

.work-item {
  width: 100%;
  max-width: 800px;
  background-color: rgba(0, 102, 204, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  outline: 5px solid white;
  outline-offset: 10px;
  margin-bottom: 30px;
}

.work-item h3 {
  font-size: 1.8rem;
  color: white;
  margin-bottom: 10px;
}

.work-item p.company {
  font-size: 1.2rem;
  color: darkblue;
  margin-bottom: 15px;
}

.work-item ul {
  color: white;
  font-size: 1rem;
  margin-bottom: 15px;
  list-style-type: disc;
  padding-left: 20px;
}

.work-item ul li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .work-section {
    margin: 0px 20px;
  }
}
