.projects {
  padding: 50px 20px;
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 50px;
  color: slategray;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
}

.project-card {
  display: flex;
  background-color: rgba(0, 102, 204, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  outline: 5px solid white;
  outline-offset: 5px;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(5px);
}

.project-details {
  flex: 1;
  margin-right: 20px;
}

.project-card h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
}

.project-card p {
  color: white;
  font-size: 1rem;
  margin-bottom: 15px;
}

.project-links {
  display: flex;
  gap: 10px;
}

.project-button {
  padding: 10px 20px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
  margin-left: 5px;
  font-size: 1rem;
}

.project-button:hover {
  background-color: rgba(112, 128, 144, 0.5);
}

.project-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  outline: 2px solid darkblue;
  border-radius: 5px;
}

.project-image:hover {
  opacity: 0.8;
}

.project-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.project-icon {
  margin: 0 5px;
  color: darkblue;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.project-icon:hover {
  color: whitesmoke;
}

@media (max-width: 768px) {
  .project-grid {
    grid-template-columns: 1fr;
    margin-left: 0px;
    margin-right: 0px;
  }

  .projects h2 {
    font-size: 2rem;
  }

  .project-card {
    flex-direction: column;
    text-align: center;
  }

  .project-details {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .project-image {
    max-width: 100%;
  }

  .project-links {
    justify-content: center;
  }
}
