.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.main h1 {
  font-size: 8rem;
  font-weight: 900;
  color: white;
  outline: 5px solid white;
  outline-offset: 10px;
  background-color: rgba(0, 102, 204, 0.8);
  padding: 20px;
  border-radius: 10px;
  letter-spacing: 4px;
}

.main p {
  align-self: center;
  font-size: 2rem;
  font-weight: 500;
  color: slategray;
  padding: 5px;
}

.opacity {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.arrow {
  margin-top: 300px;
  cursor: pointer;
  opacity: 0;
  animation: moveUpDown 2s ease-in-out infinite;
  transition: opacity 0.5s ease-in;
}

.arrow:hover svg path {
  stroke: slategray;
}

.fade-in {
  opacity: 1;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.arrow:hover {
  transform: translateY(5px);
  animation: none;
}

@media (max-width: 768px) {
  .main h1 {
    font-size: 4rem;
    outline: 4px solid white;
    outline-offset: 8px;
  }
}

@media (max-width: 480px) {
  .main h1 {
    font-size: 2.5rem;
    outline: 3px solid white;
    outline-offset: 6px;
  }

  .arrow {
    margin-top: 200px;
  }
}
